@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300&display=swap");

$theme-colors: (
  "primary": #9c2e9c,
);

$primary-color: #9c2e9c;
$header-color: #a8c65e;

$body-bg: transparent;

@import "~bootstrap/scss/bootstrap";

.modal-content {
  background-color: #3e3e3e;
}

.modal-header {
  border-bottom: 1px solid #191919;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1,
h2,
h3,
h4,
h5 {
  color: $header-color;
  font-family: "JetBrains Mono", monospace;
}

p {
  color: whitesmoke;
  font-family: "JetBrains Mono", monospace;
}

a,
.nav-link {
  color: $primary-color;
  font-family: "JetBrains Mono", monospace;
}
a:hover,
.nav-link:hover {
  color: darken($primary-color, 10%);
}
