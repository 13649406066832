@function multiple-box-shadow($n) {
  $value: "#{random(2000)}px #{random(2000)}px #FFF";
  @for $i from 2 through $n {
    $value: "#{$value} , #{random(2000)}px #{random(2000)}px #FFF";
  }

  @return unquote($value);
}

$shadows-small: multiple-box-shadow(2000);
$shadows-medium: multiple-box-shadow(500);
$shadows-big: multiple-box-shadow(50);

html {
  height: 100vh;
  background: radial-gradient(ellipse at bottom, #18232f 0%, #040406 100%);
  overflow: hidden;
}

body {
  height: 100vh;
}

#stars {
  width: 1px;
  height: 1px;
  border-radius: 0.5px;
  background: transparent;
  box-shadow: $shadows-small;
  animation: animStar 1000s linear infinite;
}

#stars2 {
  width: 2px;
  height: 2px;
  border-radius: 1px;
  background: transparent;
  box-shadow: $shadows-medium;
  animation: animStar 800s linear infinite;
}

#stars3 {
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  background: transparent;
  box-shadow: $shadows-big;
  animation: animStar 600s linear infinite;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}
